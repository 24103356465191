import React from 'react'
import { graphql } from 'gatsby';
import MainLayout from "../layouts/main-layout";

import {TCommonTemplateData} from "../models/common-template-data.model";

interface IOrderLabelsPage {
    readonly data: TCommonTemplateData;
}

const OrderLabelsPage: React.FC<IOrderLabelsPage> = ({ data }) => {
    return (
        <MainLayout
            showRecipesSlider={false}
            includeHeaderPadding={false}
            page={data.page}
        >
        </MainLayout>
    )
}

export const query = graphql`
    query ($language: String!, $site: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }

        page: strapiPage(
            locale: { eq: $language }
            site: { eq: $site }
            isDummyContent: { eq: false }
        ) {
            ...pageFields
        }
    }
`;

export default OrderLabelsPage